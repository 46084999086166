/** ***************************************************************************
 * Common / Organisms / Header
 *************************************************************************** */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import { xl, xxl } from '../../../../src/styles/shared/variables.module.scss';

class Timeout {
  constructor(fn, interval) {
    this.id = setTimeout(fn, interval);
    this.cleared = false;
  }

  clear() {
    this.cleared = true;
    clearTimeout(this.id);
  }
}

class HeaderComponent {
  constructor(element) {
    this.element = element;
    this.context = this.element.classList.contains('DIGITAL')
      ? 'DIGITAL'
      : 'E_COMMERCE';
    this.toggle = document.getElementById('cn-c-header__toggle');
    this.topNavLogo = this.element.querySelector(
      '.cn-c-top_nav .row-start > .cn-c-main_brand_ref'
    );
    this.navigation = this.element.querySelector('.cn-c-navigation');
    this.mobileLevelMenu = this.navigation.querySelector(
      '.content-wrapper > div'
    );
    this.navigationButtons = document.querySelectorAll('.cn-c-navigation li a');

    this.languageListComponent = this.element.querySelector(
      '.row-end .cn-c-language_list'
    );
    this.activeLanguageLink = this.element.querySelector(
      '.row-end .cn-c-language_list .is-selected'
    );

    this.searchButton = this.element.querySelector(
      '.row-end .cn-c-button.search-button'
    );
    this.searchBarComponent = this.element.querySelector('.cn-c-search_bar');

    this.setupEventListeners();

    this.toggle.onchange = this.onchange;
    this.navigationButtons.forEach(
      (button) =>
        (button.onclickNavigationButtons = this.onclickNavigationButtons)
    );

    /* reference: https://bencentra.com/code/2015/02/27/optimizing-window-resize.html */
    this.delay = 100;
    this.throttled = false;
    window.addEventListener('resize', this.onresize); // FIXME: use a resize observer
    window.addEventListener('scroll', this.onscroll);
    this.navigation.addEventListener('click', this.onclickOutside);
    this.activeLanguageLink.addEventListener(
      'click',
      this.onclickActiveLanguage
    );
    this.searchButton.addEventListener('click', this.onclickSearchButton);

    this.style = getComputedStyle(document.body);
    this.prevScrollpos = window.scrollY;
    this.runningTimeout;

    /* Get the offset to fix the header after its height scroll position */
    this.headerBottom = this.element.offsetTop + this.element.offsetHeight;

    this.setTopNavContextLink();
    this.setInitialFocusLockStateOnMenu();
  }

  destroy() {
    window.removeEventListener(this.onresize);
    this.toggle.onchange = undefined;
  }

  setupEventListeners() {
    this.onresize = () => {
      if (
        window.matchMedia(
          `(min-width: ${this.context == 'E_COMMERCE' ? xl : xxl})`
        ).matches
      ) {
        this.toggle.checked = false;
        this.toggle.onchange = undefined;
        this.mobileLevelMenu.removeEventListener('keydown', this.onkeydown);
        this.element.classList.remove('is-open');
        enableBodyScroll(this.element);
        document.documentElement.style.overflowY = 'unset';
      } else {
        this.toggle.onchange = this.onchange;
        this.mobileLevelMenu.addEventListener('keydown', this.onkeydown);
      }
      this.setTopNavContextLink();
    };
    this.onchange = (event) => {
      if (event.target.checked) {
        this.closeAll();
        disableBodyScroll(this.element, { reserveScrollBarGap: true });
        document.documentElement.style.overflowY = 'hidden';
        window.addEventListener;
      } else {
        enableBodyScroll(this.element);
        document.documentElement.style.overflowY = 'unset';
      }
      this.element.classList.toggle('is-open');
    };
    this.onclickNavigationButtons = (event) => {
      this.closeNavigation();
    };
    this.onclickOutside = (event) => {
      if (event.target === this.navigation) {
        this.closeNavigation();
      }
    };
    this.onscroll = () => {
      if (window.scrollY == 0) {
        this.resetHeader();
        // } else if (this.timeout && !this.timeout.cleared) {
        //   this.timeout.clear();
      } else {
        // only run if we're not cleared
        this.toggleHeaderVisibility();
      }
      // this.timeout = this.noDelaySetTimeout(
      //   this.toggleHeaderVisibility.bind(this),
      //   this.delay
      // );
    };

    this.onkeydown = (event) => {
      let focusableElements = this.mobileLevelMenu.querySelectorAll(
        'a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
      );
      focusableElements = Array.from(focusableElements)
        .filter((el) => el.offsetParent != null)
        .filter((element) => !element.closest('.nav-expand-content'));
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement =
        focusableElements[focusableElements.length - 1];

      if (event.keyCode === 9) {
        // Trap focus within the modal on "Tab" key press
        if (event.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            event.preventDefault();
            this.toggle.focus();
          }
        } else {
          if (document.activeElement === lastFocusableElement) {
            event.preventDefault();
            this.toggle.focus();
          }
        }
      }

      if (event.keyCode === 27) {
        // Close modal on "Escape" key press
        event.stopPropagation();
        this.closeNavigation();
        this.toggle.focus();
      }
    };

    this.onclickActiveLanguage = (event) => {
      // event.preventDefault();
      this.closeMenuItemsNavigation();
      this.searchButton.classList.remove('is-active');
      this.searchBarComponent.classList.remove('is-open');
      this.languageListComponent.classList.toggle('is-open');
    };

    this.onclickSearchButton = (event) => {
      event.preventDefault();
      this.closeMenuItemsNavigation();
      this.languageListComponent.classList.remove('is-open');
      this.searchButton.classList.toggle('is-active');
      this.searchBarComponent.classList.toggle('is-open');
    };
  }

  closeMenuItemsNavigation() {
    this.navigation.cnNavigationComponent.closeAllBut();
    this.closeNavigation();
  }

  closeAll() {
    this.searchButton.classList.remove('is-active');
    this.searchBarComponent.classList.remove('is-open');
    this.languageListComponent.classList.remove('is-open');
  }

  toggleHeaderVisibility() {
    let currentScrollPos = window.scrollY;

    /* if scrolling down, let it scroll out of view as normal */
    if (this.prevScrollpos < currentScrollPos) {
      this.element.style.top = `-${this.element.offsetHeight}px`;
      // if (this.runningTimeout) clearTimeout(this.runningTimeout);
      // the timeout delay has to be the same one of header's transition duration
      // this.runningTimeout = setTimeout(() => {
      //   // this.resetHeader();
      //   clearTimeout(this.runningTimeout);
      // }, parseFloat(this.style.getPropertyValue('--cn-global--Duration--sm')));
    } else if (
      this.prevScrollpos > currentScrollPos &&
      currentScrollPos > this.headerBottom
    ) {
      /* otherwise if we're scrolling up, fix the nav to the top */
      this.element.classList.add('fixed-top');
      this.element.style.top = '0';
    }

    this.prevScrollpos = currentScrollPos;

    if (this.timeout) this.timeout.clear();
  }

  noDelaySetTimeout(func, interval) {
    // func();
    return new Timeout(func, interval);
  }

  setTopNavContextLink() {
    if (this.context === 'DIGITAL') {
      this.topNavContextLabel = this.topNavLogo.querySelector(
        '.cn-c-header_heading .context-label'
      );
      if (window.matchMedia(`(min-width: ${xxl})`).matches) {
        this.topNavLogo.setAttribute('href', '/');
        this.topNavContextLabel.innerText = 'EDITORE';
      } else {
        this.topNavLogo.setAttribute(
          'href',
          this.topNavLogo.dataset.baseurl ?? '/'
        );
        this.topNavContextLabel.innerText = 'DIGITAL';
      }
    }
  }

  setInitialFocusLockStateOnMenu() {
    if (
      !window.matchMedia(
        `(min-width: ${this.context == 'E_COMMERCE' ? xl : xxl})`
      ).matches
    ) {
      this.mobileLevelMenu.addEventListener('keydown', this.onkeydown);
    }
  }

  resetHeader() {
    this.element.classList.remove('fixed-top');
  }

  closeNavigation() {
    this.element.classList.remove('is-open');
    enableBodyScroll(this.element);
    document.documentElement.style.overflowY = 'unset';
    this.toggle.checked = false;
  }
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-header';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnHeaderComponent = new HeaderComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnHeaderComponent) {
        element.cnHeaderComponent.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { HeaderComponent };
