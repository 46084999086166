/** ***************************************************************************
 * Common / Atoms / Forms / Widgets / Custom Select
 *************************************************************************** */

class CustomSelectComponent {
  constructor(element) {
    this.element = element;
    this.innerCustomSelect = this.element.querySelector('.custom_select-inner');
    this.dropdown = this.element.querySelector('.dropdown');
    this.options = this.element.querySelectorAll('.dropdown > .option');

    this.setupEventListeners();

    this.innerCustomSelect.addEventListener('click', this.onToggleDropdown);
    this.innerCustomSelect.addEventListener('keyup', this.onToggleKeyup);
    this.options.forEach((option) => {
      option.addEventListener('click', this.onSelectOption);
      option.addEventListener('keyup', this.onOptionKeyup);
    });

    this.initialize();
  }

  initialize() {
    const selectedOption = this.element.querySelector('.option[selected]');
    if (selectedOption) {
      selectedOption.click();
    }
  }

  setupEventListeners() {
    this.onToggleDropdown = () => {
      const isExpanded =
        this.innerCustomSelect.getAttribute('aria-expanded') === 'true';

      if (isExpanded) {
        this.dropdown.style.display = 'none';
        this.innerCustomSelect.setAttribute('aria-expanded', 'false');
      } else {
        this.dropdown.style.display = 'block';
        this.innerCustomSelect.setAttribute('aria-expanded', 'true');
      }
    };

    this.onToggleKeyup = (event) => {
      const isExpanded =
        this.innerCustomSelect.getAttribute('aria-expanded') === 'true';

      switch (event.key) {
        case 'Enter':
        case ' ':
          this.onToggleDropdown();
          event.preventDefault();
          break;
        case 'ArrowDown':
          if (!isExpanded) {
            this.onToggleDropdown();
          }
          const firstOption = this.dropdown.querySelector('.option');
          firstOption.focus();
          event.preventDefault();
          break;
        case 'ArrowUp':
          if (isExpanded) {
            const options = Array.from(
              this.dropdown.querySelectorAll('.option')
            );
            options[options.length - 1].focus();
            event.preventDefault();
          }
          break;
        case 'Escape':
          if (isExpanded) {
            this.onToggleDropdown();
            this.innerCustomSelect.focus();
            event.preventDefault();
          }
          break;
        default:
          break;
      }
    };

    this.onOptionKeyup = (event) => {
      const options = Array.from(this.options);
      const currentIndex = options.indexOf(document.activeElement);

      switch (event.key) {
        case 'Enter':
        case ' ':
          this.onSelectOption(event);
          this.innerCustomSelect.focus();
          event.preventDefault();
          break;
        case 'ArrowDown':
          if (currentIndex < options.length - 1) {
            options[currentIndex + 1].focus();
          } else {
            options[0].focus();
          }
          event.preventDefault();
          break;
        case 'ArrowUp':
          if (currentIndex > 0) {
            options[currentIndex - 1].focus();
          } else {
            options[options.length - 1].focus();
          }
          event.preventDefault();
          break;
        case 'Escape':
          this.innerCustomSelect.focus();
          this.onToggleDropdown();
          event.preventDefault();
          break;
        default:
          break;
      }
    };

    this.onSelectOption = (event) => {
      event.stopPropagation();
      const option = event.currentTarget;

      const selectedOption = this.element.querySelector('.selected-option');
      const optionInner = option.innerHTML;
      const optionValue = option.getAttribute('data-value');
      selectedOption.innerHTML = optionInner;
      selectedOption.classList.remove('is-placeholder');

      const hiddenInput = this.element.querySelector('#selectedOptionInput');
      hiddenInput.value = optionValue;

      this.dropdown.style.display = 'none';
      this.innerCustomSelect.setAttribute('aria-expanded', 'false');

      const itemSelectedEvent = new Event('valueSelected');
      this.element.dispatchEvent(itemSelectedEvent);
    };
  }

  destroy() {}
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-custom_select';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnCustomSelectComponent = new CustomSelectComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnCustomSelectComponent) {
        element.cnCustomSelectComponent.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { CustomSelectComponent };
