/** ***************************************************************************
 * Common / Organisms / Notifications
 *************************************************************************** */

class NotificationsComponent {
  constructor(element) {
    this.element = element;
    this.container = this.element.querySelector(
      '.container'
    );
    this.notifications = this.element.querySelectorAll(
      '.cn-c-notification_card'
    );
    this.style = getComputedStyle(document.body);

    this.notifications.forEach((notification) => {
      this.show(notification);
      const notificationCloseButton = notification.querySelector(
        '.close-button'
      );
      notificationCloseButton.addEventListener('click', () => {
        this.remove(notification);
      });
      if (
        !(
          notification.classList.contains('level-error') ||
          notification.classList.contains('level-warning')
        )
      ) {
        setTimeout(() => {
          this.remove(notification);
        }, 5000);
      }
    });
  }

  show(notification) {
    notification.classList.remove('is-hidden');
  }

  remove(notification) {
    notification.classList.add('is-hidden');
    setTimeout(() => {
      notification.style.display = 'none'; // Rimuovi dalla visualizzazione dopo l'animazione
      this.checkIfAllNotificationsAreHidden();
    }, parseFloat(this.style.getPropertyValue('--cn-global--Duration--sm'))); // Assicurati che il timeout corrisponda alla durata dell'animazione (0.3s)
  }

  checkIfAllNotificationsAreHidden() {
    if (
      this.notifications.length > 0 &&
      Array.from(this.notifications).every((notification) => {
        return notification.classList.contains('is-hidden');
      })
    ) {
      this.container.classList.add('is-hidden');
    }
  }

  setupEventListeners() {
    this.onClickClose = () => {
      this.closeFiltersPanel();
    };
  }

  destroy() {}
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-notifications';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnNotificationsComponent = new NotificationsComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnNotificationsComponent) {
        element.cnNotificationsComponent.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { NotificationsComponent };
