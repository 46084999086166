/** ***************************************************************************
 * Utilities
 *************************************************************************** */

function getPromiseFromEvent(element, event) {
  return new Promise((resolve) => {
    const listener = () => {
      element.removeEventListener(event, listener);
      resolve();
    }
    element.addEventListener(event, listener);
  });
}

export { getPromiseFromEvent }
