/** ***************************************************************************
 * Common / Organisms / Video Section
 *************************************************************************** */

import { VideoComponent } from '../../../02-molecules/video/video';
import { lg } from '../../../../../src/styles/shared/variables.module.scss';

class VideoSectionComponent {
  constructor(element) {
    this.element = element;
    this.videoComponent = this.element.querySelector('.cn-c-video');

    this.initializeVideo();
  }

  initializeVideo() {
    if (this.videoComponent.cnVideoComponent) {
      this.videoComponent.cnVideoComponent.play();
    } else {
      this.videoComponent.cnVideoComponent = new VideoComponent(
        this.videoComponent
      );
    }
  }

  destroy() {}
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-video_section';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnVideoSectionComponent = new VideoSectionComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnVideoSectionComponent) {
        element.cnVideoSectionComponent.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { VideoSectionComponent };
