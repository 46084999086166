/** ***************************************************************************
 * Common / Organisms / Navigation
 *************************************************************************** */

class NavigationComponent {
  constructor(element) {
    this.element = element;
    this.firstLevelMenu = this.element.querySelector('.content-wrapper > div');
    this.header = document.querySelector('.cn-c-header');
    this.menuItems = this.firstLevelMenu.querySelectorAll(
      ':scope .cn-c-menu_item'
    );
    this.navExpand = new Map([]);
    this.menuItems.forEach((item, index) => {
      if (item.classList.contains('nav-expand')) {
        this.navExpand.set(index, item);
      }
    });

    this.initialize();
  }

  initialize() {
    this.navExpand.forEach((item) => {
      const backLinkItem = item.querySelector('.nav-back-link');
      backLinkItem
        .querySelector('.back-link-wrapper')
        .addEventListener('click', () => this.closeMenu(item));
      item
        .querySelector('.nav-expand-link')
        .addEventListener('click', (event) => {
          backLinkItem.querySelector('.cn-c-label').innerText =
            event.target.innerText;
          this.header.cnHeaderComponent.closeAll();
          this.closeAllBut(item);
          if (item.classList.contains('is-active')) {
            this.closeMenu(item);
          } else {
            item.classList.add('is-active');
            const navExpandLink = item.querySelector('.nav-expand-link');
            navExpandLink.setAttribute('aria-expanded', true);
            this.changeIcon(item, 'minus');
          }
        });
      item
        .querySelector('.nav-expand-link')
        .addEventListener('keydown', (event) => {
          if (event.keyCode == 13 || event.keyCode == 32) {
            this.focusFirstElementOf(item);
          }
        });
      const navExpandContent = item.querySelector('.nav-expand-content');
      navExpandContent.addEventListener('keydown', (event) => {
        this.trapFocus(event, item);
      });
    });
  }

  getKeyByValue(map, searchValue) {
    for (let [key, value] of map.entries()) {
      if (value === searchValue) return key;
    }
  }

  focusFirstElementOf(item) {
    const navExpandContent = item.querySelector('.nav-expand-content');
    let focusableElements = navExpandContent.querySelectorAll(
      'a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
    );
    focusableElements = Array.from(focusableElements).filter(
      (el) => el.offsetParent != null
    );
    const firstFocusableElement = focusableElements[0];
    setTimeout(() => firstFocusableElement.focus(), 300);
  }

  trapFocus(event, item) {
    // Find the first and last focusable elements inside the modal
    const navExpandContent = item.querySelector('.nav-expand-content');
    let focusableElements = navExpandContent.querySelectorAll(
      'a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
    );
    focusableElements = Array.from(focusableElements).filter(
      (el) => el.offsetParent != null
    );
    const firstFocusableElement = focusableElements[0];
    // firstFocusableElement.focus();
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];
    if (event.keyCode === 9) {
      // Trap focus within the modal on "Tab" key press
      if (event.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          event.preventDefault();
          event.stopPropagation();
          this.closeMenu(item);
          // lastFocusableElement.focus();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          event.preventDefault();
          event.stopPropagation();
          this.closeMenu(item);
          this.menuItems[this.getKeyByValue(this.navExpand, item) + 1]
            .querySelector(':scope .cn-c-button')
            .focus();
          // firstFocusableElement.focus();
        }
      }
    }

    if (event.keyCode === 27) {
      // Close modal on "Escape" key press
      event.stopPropagation();
      this.closeMenu(item);
    }
  }

  closeAllBut(currentItem) {
    this.navExpand.forEach((item) => {
      if (item !== currentItem && item.classList.contains('is-active')) {
        this.closeMenu(item);
      }
    });
  }

  changeIcon(item, icon) {
    const currentAttribute = item
      .querySelector('svg use')
      .getAttribute('href')
      .substring(
        0,
        item.querySelector('svg use').getAttribute('href').lastIndexOf('#')
      );
    item
      .querySelector('svg use')
      .setAttribute('xlink:href', `${currentAttribute}#${icon}`);
    item
      .querySelector('svg use')
      .setAttribute('href', `${currentAttribute}#${icon}`);
  }

  closeMenu(item) {
    item.classList.remove('is-active');
    const navExpandLink = item.querySelector('.nav-expand-link');
    navExpandLink.setAttribute('aria-expanded', false);
    navExpandLink.focus();
    this.changeIcon(item, 'plus');
  }

  destroy() {}
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-navigation';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnNavigationComponent = new NavigationComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnNavigationComponent) {
        element.cnNavigationComponent.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { NavigationComponent };
