/** ***************************************************************************
 * Common / Organisms / Search Bar
 *************************************************************************** */

class SearchBarComponent {
  constructor(element) {
    this.element = element;
    this.filtersForm = document.querySelector('.cn-c-filters_panel form');
    this.submitButton = this.element.querySelector(
      '.cn-c-button[type="submit"]'
    );

    this.setupEventListeners();

    this.submitButton.addEventListener('click', this.onSearchSubmit.bind(this));
  }

  setupEventListeners() {
    this.onSearchSubmit = (event) => {
      event.preventDefault();

      if (this.filtersForm && this.element.classList.contains('catalog')) {
        const filtersInputs = this.filtersForm.querySelectorAll('input');

        filtersInputs.forEach((input) => {
          const hiddenInput = document.createElement('input');
          hiddenInput.type = input.type;
          hiddenInput.name = input.name;
          hiddenInput.setAttribute('value', input.value);
          if (input.checked) {
            hiddenInput.setAttribute('checked', '');
          }
          hiddenInput.style.display = 'none';

          this.element.appendChild(hiddenInput);
        });
      }
      this.element.submit();
    };
  }

  destroy() {}
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-search_bar';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnSearchBarComponent = new SearchBarComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnSearchBarComponent) {
        element.cnSearchBarComponent.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { SearchBarComponent };
