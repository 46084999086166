/** ***************************************************************************
 * Common / Organisms / Catalog Section
 *************************************************************************** */

class FiltersPanelComponent {
  constructor(element) {
    this.element = element;
    this.form = this.element.querySelector('form');
    this.searchBar = document.querySelector('.cn-c-search_bar.catalog');
    this.checkboxfilters = this.element.querySelectorAll(
      'input[type="checkbox"]'
    );
    this.submitButton = this.element.querySelector(
      '.cn-c-button[type="submit"]'
    );

    this.setupEventListeners();

    this.checkboxfilters.forEach((checkbox) => {
      checkbox.addEventListener('change', this.onChange);
    });
    this.submitButton.addEventListener('click', this.onFilterSubmit.bind(this));

    this.calculateChildrenStatus();
  }

  setupEventListeners() {
    this.onChange = (event) => {
      const hasChildren = event.target.getAttribute('data-hasChildren');
      if (hasChildren) {
        this.toggleAllChildren(event.target.id, event.target.checked);
      }

      const hasParent = event.target.getAttribute('data-parent');
      if (hasParent) {
        this.toggleParent(hasParent);
      }
    };

    this.onFilterSubmit = (event) => {
      event.preventDefault();

      if (this.searchBar) {
        const searchInput = this.searchBar.querySelector('input');

        const hiddenInput = document.createElement('input');
        hiddenInput.type = searchInput.type;
        hiddenInput.name = searchInput.name;
        hiddenInput.setAttribute('value', searchInput.value);
        hiddenInput.style.display = 'none';

        this.form.appendChild(hiddenInput);
      }
      this.form.submit();
    };
  }

  calculateChildrenStatus(event) {
    Array.from(this.checkboxfilters).forEach((checkbox) => {
      if (checkbox.getAttribute('data-hasChildren') && checkbox.checked) {
        this.toggleAllChildren(checkbox.id, true);
      }
      if (checkbox.getAttribute('data-parent')) {
        this.toggleParent(checkbox.getAttribute('data-parent'));
      }
    });
  }

  toggleAllChildren(parentId, parentStatus) {
    Array.from(this.checkboxfilters)
      .filter((el) => el.getAttribute('data-parent') === parentId)
      .forEach((checkbox) => {
        checkbox.checked = parentStatus;
      });
  }

  toggleParent(parentId) {
    const parentCheckbox = Array.from(this.checkboxfilters).find(
      (el) => el.id === parentId
    );
    const siblingsCheckbox = Array.from(this.checkboxfilters).filter(
      (el) => el.getAttribute('data-parent') === parentId
    );
    if (siblingsCheckbox.every((sibling) => sibling.checked == true)) {
      parentCheckbox.checked = true;
    }
    if (siblingsCheckbox.some((sibling) => sibling.checked == false)) {
      parentCheckbox.checked = false;
    }
  }

  destroy() {}
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-filters_panel';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnFiltersPanelComponent = new FiltersPanelComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnFiltersPanelComponent) {
        element.cnFiltersPanelComponent.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { FiltersPanelComponent };
