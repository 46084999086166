/** ***************************************************************************
 * Atoms / Cursor
 *************************************************************************** */

const CustomCursor = require('custom-cursor.js').default;

const cursorDefaultFocusClass = 'cursor--default';
const cursorBackdropFocusClass = 'cursor--backdrop';
const cursorIndexBackdropFocusClass = 'cursor--index-backdrop';
const cursorFocusedFocusClass = 'cursor--focused';

class CnCursor {
  constructor(element) {
    this.element = element;

    // Following options represent the defaults
    this.options = {
      // Whether or not the true cursor should be hidden when the custom cursor is initialized
      hideTrueCursor: false,

      // Array of DOM elements/selector strings that add interactions on hover
      focusElements: [
        {
          elements: '.cn-u-button',
          focusClass: cursorDefaultFocusClass,
        },
        {
          elements: '.default-cursor',
          focusClass: cursorDefaultFocusClass,
        },
        {
          elements: '.custom-cursor',
          mouseenter: () => {
            // Run in addition to adding the focusClass
            this.customCursor.hideTrueCursor();
          },
          mouseleave: () => {
            // Run in addition to removing the focusClass
            this.customCursor.unhideTrueCursor();
          },
        },
        {
          elements: '.backdrop',
          focusClass: cursorBackdropFocusClass,
          mouseenter: () => {
            this.customCursor.enable();
          },
          mouseleave: () => {
            this.customCursor.disable();
          },
        },
        {
          elements: '.index--backdrop',
          focusClass: cursorIndexBackdropFocusClass,
          mouseenter: () => {
            this.customCursor.enable();
          },
          mouseleave: () => {
            this.customCursor.disable();
          },
        },
        'a',
      ],

      // Class applied when the true cursor is hovering over a focusElement
      focusClass: cursorFocusedFocusClass,
    };

    this.initialize();
  }

  initialize() {
    this.customCursor = new CustomCursor(this.element, this.options)
      .setPosition(-200, -200)
      .initialize();
    this.customCursor.enable();
  }

  destroy() {
    this.customCursor.disable();
    // Totally useless to show the cursor immediately when modal is closed
    const focusedClassToRemove = [
      cursorDefaultFocusClass,
      cursorBackdropFocusClass,
      cursorFocusedFocusClass,
    ];
    this.customCursor.element.classList.remove(...focusedClassToRemove);
  }
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-cursor .cursor-shape';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnCursor = new CnCursor(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnCursor) {
        element.cnCursor.destroy();
        element.cnCursor = undefined;
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  setTimeout(() => initialize());
});

export { CnCursor };
