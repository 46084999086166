/** ***************************************************************************
 * Common / Molecules / Field
 *************************************************************************** */

class ConchigliaFormField {
  constructor(element) {
    this.element = element;
    this.inputElement = this.element.querySelector('input');
    this.labelElement = this.element.querySelector('label');
    this.selectElement = this.element.querySelector('select');
    this.textareaElement = this.element.querySelector('textarea');
    this.loadedFileElement = this.element.querySelector('.loaded-file');
    if (this.inputElement) {
      this.inputType = this.inputElement.getAttribute('type');
    }

    if (this.inputType == 'file') {
      this.inputElement.addEventListener('change', () => {
        if (this.inputElement.value) {
          this.loadedFileElement.childNodes[1].nodeValue =
            this.inputElement.value.match(/[\/\\]([\w\d\s\.\-\(\)]+)$/)[1];
          this.loadedFileElement.classList.add('active');
        } else {
          this.loadedFileElement.childNodes[1].nodeValue =
            'Nessun file selezionato';
          this.loadedFileElement.classList.remove('active');
        }
      });
    }

    if (this.inputType != 'search') {
      if (this.textareaElement) {
        this.textareaElement.addEventListener('input', (e) => {
          this.showLabelFor(this.textareaElement);
        });
      } else if (this.inputElement) {
        this.eyeIcon = this.element.querySelector('.eye-icon');

        this.inputElement.addEventListener('input', (e) => {
          if (this.inputType == 'password' && this.eyeIcon) {
            if (this.inputElement.value) {
              this.eyeIcon.style.display = 'block';
            } else {
              this.eyeIcon.style.display = 'none';
            }
          }
          this.showLabelFor(this.inputElement);
        });

        if (this.inputType == 'password' && this.eyeIcon) {
          this.eyeIcon.addEventListener('click', (e) => {
            // toggle the type attribute
            const type =
              this.inputElement.getAttribute('type') === 'password'
                ? 'text'
                : 'password';
            this.inputElement.setAttribute('type', type);
            // toggle the eye / eye slash icon
            this.eyeIcon.classList.toggle('active');
          });
        }
      }

      this.initLabels();
    }
  }

  initLabels() {
    if (this.inputElement) {
      this.showLabelFor(this.inputElement);
    } else if (this.selectElement) {
      this.showLabelFor(this.selectElement);
    } else if (this.textareaElement) {
      this.showLabelFor(this.textareaElement);
    }
  }

  showLabelFor(element) {
    if (this.inputType == 'file') {
      this.labelElement.style.display = 'block';
    } else if (element.tagName !== 'SELECT' && element.value.trim() == '') {
      this.labelElement.style.display = 'none';
    } else {
      if (this.inputType != 'checkbox' && this.inputType != 'radio') {
        this.labelElement.style.display = 'block';
      }
    }
  }
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-field';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnFormField = new ConchigliaFormField(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      const container = element.closest('.cn-c-field');
      if (container.cnFormField) {
        container.cnFormField.destroy();
        container.cnFormField = undefined;
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { ConchigliaFormField };
