/** ***************************************************************************
 * Common / Organisms / Modal
 *************************************************************************** */

import MicroModal from 'micromodal';
import { VideoComponent } from '../../02-molecules/video/video';

class ModalComponent {
  constructor(element, contextId) {
    this.element = element;
    this.contextId = contextId;
    if (contextId) {
      this.element.setAttribute('id', `modal-${contextId}`);
    }
    this.videoComponent = this.element.querySelector('.cn-c-video');
    this.cursorComponent = document.querySelector('.cn-c-cursor .cursor-shape');
    this.backdrop = this.element.querySelector('.backdrop');
    this.closeButton = this.element.querySelector('.cn-c-button--close');
    this.iframe = this.element.querySelector('iframe');

    /* Enable possibility to pause youtube video in iframe */
    if (this.contextId == 'video') {
      if (this.iframe.src.includes('?')) {
        this.iframe.src += '&';
      } else {
        this.iframe.src += '?';
      }
      this.iframe.src += 'enablejsapi=1';
    }

    MicroModal.init();

    this.setUpEventListeners();
  }

  open(videoSrc) {
    MicroModal.show(this.element.id, {
      // onShow: (modal) => console.info(`${modal.id} is shown`), // [1]
      onClose: (modal) => {
        this.onClose();
      }, // [2]
      openTrigger: 'micromodal-open', // [3]
      closeTrigger: 'micromodal-close', // [4]
      openClass: 'is-open', // [5]
      disableScroll: true, // [6]
      disableFocus: false, // [7]
      awaitOpenAnimation: false, // [8]
      awaitCloseAnimation: false, // [9]
      debugMode: true, // [10]
    });

    if (videoSrc) {
      if (this.videoComponent.cnVideoComponent) {
        this.videoComponent.cnVideoComponent.play();
      } else {
        this.videoComponent.cnVideoComponent = new VideoComponent(
          this.videoComponent,
          videoSrc,
          true
        );
      }
    }

    if (this.closeButton.offsetParent !== null) {
      this.closeButton.focus();
    }
  }

  close() {
    MicroModal.close(this.element.id);
  }

  setUpEventListeners() {
    document.addEventListener('keyup', this.keyPress.bind(this));
    this.closeButton.addEventListener('click', this.close.bind(this));
    this.element.addEventListener('keydown', (event) => this.trapFocus(event));
  }

  trapFocus(event) {
    let focusableElements = this.element.querySelectorAll(
      'a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
    );
    focusableElements = Array.from(focusableElements).filter(
      (el) => el.offsetParent != null
    );
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];
    if (event.keyCode === 9) {
      // Trap focus within the modal on "Tab" key press
      if (event.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          event.preventDefault();
          event.stopPropagation();
          lastFocusableElement.focus();
        }
      } else {
        // if (document.activeElement === lastFocusableElement) {
        //   event.preventDefault();
        //   event.stopPropagation();
        //   firstFocusableElement.focus();
        // }
      }
    }
  }

  keyPress(e) {
    if (e.key === 'Escape') {
      this.close();
    }
  }

  onClose() {
    if (this.videoComponent) {
      this.videoComponent.cnVideoComponent.pause();
    }
    if (this.cursorComponent) {
      this.cursorComponent.cnCursor.destroy();
    }
    if (this.contextId == 'video') {
      this.stopYouTubeVideo();
    }
    this.element.dispatchEvent(new Event('modal:close'));
  }

  stopYouTubeVideo() {
    this.iframe.contentWindow.postMessage(
      JSON.stringify({ event: 'command', func: 'pauseVideo' }),
      '*'
    );
  }
}

export { ModalComponent as default };
