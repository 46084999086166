/** ***********************************************************************************
 * Digital / Organisms / CMS Plugins / Section
 *********************************************************************************** */

class SectionComponent {
  constructor(element) {
    this.element = element;
    this.form = element.querySelector(':scope > header form');

    if (this.form) {
      this.form.onsubmit = (event) => {
        event.preventDefault();
        const buttons = document.querySelectorAll('.cn-c-section > header .cn-c-button');
        const ownButton = element.querySelector(':scope > header .cn-c-button');
        const form = event.target;
        const actionField = form.querySelector('[name="_action"]');
        const data = new FormData(form);
        const csrf = form.querySelector('[name="csrfmiddlewaretoken"]')
          ? form.querySelector('[name="csrfmiddlewaretoken"]').value
          : null;

        buttons.forEach((button) => {
          button.setAttribute('disabled', '');
        });

        fetch(form.action, {
          method: form.method,
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRFToken': csrf,
          },
          body: new URLSearchParams(data),
        }).then((response) => {
          if (response.redirected) {
            buttons.forEach((button) => {
              button.classList.remove('cn-m-active');
            });
            if (actionField.value == 'create') {
              actionField.value = 'delete';
              ownButton.classList.add('cn-m-active');
            } else {
              // ownButton.classList.remove('cn-m-active');
              actionField.value = 'create';
            }
          } else {
            console.error(`Wrong response status: ${response.status}`);
          }
        }).catch((error) => {
          console.error(error);
        });

        buttons.forEach((button) => {
          button.removeAttribute('disabled');
        });
      };
    }
  }

  destroy() {
    if (this.form) {
      this.form.onsubmit = undefined;
    }
  }
}


window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-section';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnSectionComponent = new SectionComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnSectionComponent) {
        element.cnSectionComponent.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { SectionComponent };
