/** ***************************************************************************
 * Ecommerce / Organisms / Newsletter
 *************************************************************************** */

class NewsletterComponent {
  constructor(element) {
    this.element = element;
    this.form = this.element.querySelector('form');
    this.button = this.element.querySelector('button');

    this.form.onsubmit = this.onsubmit;
  }

  setLabel(label) {
    this.button.querySelector('span').textContent = label;
  }

  onsubmit = (event) => {
    event.preventDefault();

    const buttonLabel = this.button.querySelector('span').textContent;
    const form = event.target;
    const data = new FormData(form);
    const csrf = form.querySelector('[name="csrfmiddlewaretoken"]')
      ? form.querySelector('[name="csrfmiddlewaretoken"]').value
      : null;

    this.button.setAttribute('disabled', '');

    fetch(form.action, {
      method: form.method,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': csrf,
      },
      body: new URLSearchParams(data),
    })
      .then((response) => {
        event.target
          .querySelectorAll('input:not([type="checkbox"])')
          .forEach((field) => {
            field.value = '';
          });
        event.target
          .querySelectorAll('input[type="checkbox"]')
          .forEach((field) => {
            field.checked = false;
          });
        setTimeout(() => {
          this.button.addEventListener(
            'transitionend',
            () => {
              this.setLabel(buttonLabel);
            },
            { once: true }
          );
          this.button.removeAttribute('disabled');
        }, 3000);
        this.setLabel(gettext('Thanks'));
      })
      .catch((error) => {
        console.log(error);
        this.setLabel(buttonLabel);
        this.button.removeAttribute('disabled');
      });
  };

  destroy() {
    this.form.onsubmit = undefined;
  }
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-newsletter';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnNewsletter = new NewsletterComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnNewsletter) {
        element.cnNewsletter.destroy();
        element.cnNewsletter = undefined;
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});
