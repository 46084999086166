/** ***************************************************************************
 * Digital / Organisms / Figure
 *************************************************************************** */

import { getPromiseFromEvent } from '/src/scripts/utils';


class FigureComponent {
  constructor(element) {
    this.element = element;
    this.toggle = element.querySelector('input');
    this.button = element.querySelector('.cn-c-camera_button');
    this.caption = element.querySelector('figcaption');

    this.setUpEventListeners();

    if (this.toggle && this.caption) {
      this.button.addEventListener('click', this.onclick);
    }
  }

  destroy() {
    if (this.toggle && this.caption) {
      this.button.removeEventListener('click', this.onclick);
    }
  }

  showCaption() {
    const captionBlockSize = this.caption.scrollHeight;
    this.caption.addEventListener('transitionend', (event) => {
      if (event.propertyName == 'block-size') {
        this.element.style.removeProperty('--cn-c-figure__caption--BlockSize');
        event.target.removeEventListener('transitionend', arguments.callee);
      }
    });
    this.element.style.setProperty('--cn-c-figure__caption--BlockSize', `${captionBlockSize}px`);
    return getPromiseFromEvent(this.caption, 'transitionend');
  }

  async hideCaption() {
    const captionBlockSize = this.caption.scrollHeight;
    this.caption.addEventListener('transitionend', (event) => {
      if (event.propertyName == 'block-size') {
        this.element.style.removeProperty('--cn-c-figure__caption--BlockSize');
        event.target.removeEventListener('transitionend', arguments.callee);
      }
    });

    this.caption.style.setProperty('transition', '');
    requestAnimationFrame(() => {
      this.element.style.setProperty('--cn-c-figure__caption--BlockSize', `${captionBlockSize}px`);
      this.caption.style.removeProperty('transition');
      requestAnimationFrame(() => {
        this.element.style.setProperty('--cn-c-figure__caption--BlockSize', '0');
      });
    });

    return getPromiseFromEvent(this.caption, 'transitionend');
  }

  setUpEventListeners() {
    this.onclick = async (event) => {
      if (this.toggle.checked) {
        event.preventDefault()
        await this.hideCaption();
        this.toggle.checked = false;
      } else {
        await this.showCaption();
      }
    }
  }
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-figure';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnFigureComponent = new FigureComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnFigureComponent) {
        element.cnCartItemComponent.destroy();
        element.cnFigureComponent = undefined;
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});
