/** ***************************************************************************
 * Common / Atoms / Add to Cart CTA
 *************************************************************************** */

class AddToCartCtaComponent {
  constructor(element) {
    this.element = element;
    this.style = getComputedStyle(document.body);
    this.itemCartAmount = this.element.querySelector('.field > input');
    this.amountLabel = this.element.querySelector('.item-amount');
    this.decrementButton = this.element.querySelector('.decrement');
    this.incrementButton = this.element.querySelector('.increment');
    this.submitButton = this.element.querySelector(
      '.cn-c-button[type="submit"]'
    );

    this.setupEventListeners();

    if (this.element.tagName === 'FORM') {
      this.submitButton.addEventListener('click', this.onSubmit.bind(this));
    }

    this.decrementButton.addEventListener('click', () =>
      this.toggleQuantity('decrement')
    );
    this.incrementButton.addEventListener('click', () =>
      this.toggleQuantity('increment')
    );
  }

  setupEventListeners() {
    this.onSubmit = (event) => {
      event.preventDefault();
      this.element.classList.add('is-loading');

      setTimeout(() => {
        this.element.submit();
      }, parseFloat(this.style.getPropertyValue('--cn-global--Duration--xl')));
    };
  }

  toggleQuantity(action) {
    if (action === 'decrement' && this.itemCartAmount.value > 1) {
      this.itemCartAmount.value--;
    } else if (action === 'increment') {
      this.itemCartAmount.value++;
    }
    this.amountLabel.innerText = this.formatAmount(this.itemCartAmount.value);
  }

  formatAmount(amount) {
    if (amount < 10) {
      return `0${amount}`;
    }
    return amount;
  }

  destroy() {}
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-add_to_cart_cta';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnAddToCartCtaComponent = new AddToCartCtaComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnAddToCartCtaComponent) {
        element.cnAddToCartCtaComponent.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { AddToCartCtaComponent };
