/** ***************************************************************************
 * Common / Organisms / Catalog Section
 *************************************************************************** */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import { lg } from '../../../../src/styles/shared/variables.module.scss';

class CatalogSectionComponent {
  constructor(element) {
    this.element = element;
    this.filtersTriggerBar = this.element.querySelector('.filters-trigger-bar');
    this.filtersTrigger = this.element.querySelector('.filters-trigger');
    this.filtersPanel = this.element.querySelector('.cn-c-filters_panel');
    this.filtersPanelClose = this.element.querySelector(
      '.cn-c-filters_panel .close-button'
    );
    this.listWrapper = this.element.querySelector('.list-wrapper');

    this.setupEventListeners();

    if (this.filtersTriggerBar) {
      this.filtersTrigger.addEventListener('click', this.onClick);
      this.filtersPanel.addEventListener('click', this.onClickOutside);
      this.filtersPanelClose.addEventListener('click', this.onClickClose);

      // Needed to make sure the calculation on clientHeight are made when images are loaded and take the spaces we are especting them to take.
      window.onload = () => {
        const observer = new IntersectionObserver(
          this.intersectCatalogSection.bind(this),
          {
            threshold: Math.min(
              1,
              (window.innerHeight / element.clientHeight) * 0.6
            ),
          }
        );
        observer.observe(this.element);
      };
      window
        .matchMedia(`(min-width: ${lg})`)
        .addEventListener('change', this.onMatchMedia);
    }
  }

  setupEventListeners() {
    this.onClick = (event) => {
      if (!this.listWrapper.classList.contains('is-open')) {
        if (!window.matchMedia(`(min-width: ${lg})`).matches) {
          disableBodyScroll(this.filtersPanel, { reserveScrollBarGap: true });
        }
      } else {
        enableBodyScroll(this.filtersPanel);
      }
      this.listWrapper.classList.toggle('is-open');
      this.filtersTrigger.classList.toggle('is-active');
    };
    this.onClickClose = () => {
      this.closeFiltersPanel();
    };
    this.onClickOutside = (event) => {
      if (event.target === this.filtersPanel) {
        this.closeFiltersPanel();
      }
    };

    this.onMatchMedia = (matches) => {
      if (matches) {
        this.closeFiltersPanel();
      }
    };
  }

  closeFiltersPanel() {
    this.listWrapper.classList.remove('is-open');
    this.filtersTrigger.classList.remove('is-active');
    enableBodyScroll(this.filtersPanel);
  }

  intersectCatalogSection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.filtersTriggerBar.classList.add('is-visible');
      } else {
        this.filtersTriggerBar.classList.remove('is-visible');
      }
    });
  }

  destroy() {}
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-catalog_section';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnCatalogSectionComponent = new CatalogSectionComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnCatalogSectionComponent) {
        element.cnCatalogSectionComponent.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { CatalogSectionComponent };
